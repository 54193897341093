
import React , {useState} from 'react';
import { ThemeProvider } from 'styled-components';
import { StyledApp, lightTheme, darkTheme } from '../styles/themes';


//Navbar
import MoonIcon from '../components/switch/icons/MoonIcon';
import SunIcon from '../components/switch/icons/SunIcon';
import Switch from '../components/switch/Switch';

import { Nav, Left } from '../components/componetsStyles/NavbarStyles2';

//components
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';


const Home = () => {
  const [theme, setTheme] = useState('dark');
  const isDarkTheme = theme === 'dark';

  const toggleTheme = () => {
    setTheme(isDarkTheme ? 'light' : 'dark')
  }


  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <StyledApp>
        <Nav>
          <Navbar />
          <Left>
            <SunIcon />
              <Switch toggleTheme={toggleTheme}/>
            <MoonIcon />
          </Left>
        </Nav> 
        <Hero />
        <Features />
        <Services />
        <Contact />
        <Footer />
        
        
        
      </StyledApp> 
  </ThemeProvider>


  )
}

export default Home