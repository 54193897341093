import React, { useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
// import { Name } from "./componetsStyles/TestStyles";
import { Brand, ItemWrap, ListContainer, MenuItem, Toggle } from "./componetsStyles/NavbarStyles2";



const Navbar = () => {

    const [navbarState, setNavbarState] = useState(false);
    
    
  return (
    <>
        <Brand >
              <h1 >eppe</h1>
              <Toggle>

                {navbarState ? (
                  <VscChromeClose onClick={() => setNavbarState(false)} />
                ) : (
                  <GiHamburgerMenu onClick={() => setNavbarState(true)} />
                )}

              </Toggle>
        </Brand>    

        <ListContainer>
          <ItemWrap>
            <MenuItem href="#about">De nosotros</MenuItem>
          </ItemWrap>
          <ItemWrap>
            <MenuItem href="#services">Servicios</MenuItem>
          </ItemWrap>
          <ItemWrap>
            <MenuItem href="#contact">Contacto</MenuItem>
          </ItemWrap>
        </ListContainer>
        
        </>
  )
}

export default Navbar