import styled from "styled-components";

export const Section = styled.section`

    position: relative;
    color: white;
    width: 100%;
    
    /* height: 60vh; */

    margin: 1rem auto;

   
`;

export const MainImg = styled.img`
      width: 100%;
      filter: brightness(60%);
      /* border-radius: 1rem; */
      object-fit: cover;
      height: 100vh; 
      
      @media screen and (max-width: 768px) {
		/* width: 100%; */
        height: 20rem
	}

`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

export const Title = styled.h1`
    font-size: 2.8rem;
`;

export const SubTitle = styled.h2`
    font-size: 1.5rem;
    @media screen and (max-width: 768px) {
		font-size: 1.3rem;
	}
`;

export const Button = styled.button`
    padding: 1rem;
        cursor: pointer;
        border-radius: 1rem;
        border: none;
        color: white;
        background-color: ${(props) => props.theme.normal};
        /* background-color: #0077b6; */
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
            background-color: ${(props) => props.theme.hoverDark};

          /* background-color: #023e8a; */
        }
`;
