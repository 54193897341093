import styled, {css} from "styled-components";

const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const ContactTitle = styled.h2`
    color: ${(props) => props.theme.normal};
    text-align: center;
    font-size: clamp(1.3rem, 13vw, 3.1rem);
    line-height: 1.06;
    margin: auto;
  `;

export const Section = styled.section``;

export const StyledFormWrapper = styled.div`
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;

`;

export const StyledForm = styled.form`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  ${sharedStyles}

`;

export const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}

 
`;
export const StyledButton = styled.button`
    display: block;
    padding: 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: #0077b6;
    font-size: 1.1rem;
    /* text-transform: uppercase; */
    transition: 0.3s ease-in-out;
  &:hover{
    background-color: #023e8a;
  }
`;

export const StyledFieldset = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;

  legend {
    padding: 0 10px;
  }

  label {
    padding-right: 20px;
  }

  input {
    margin-right: 10px;
  }
`;

export const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
`;