import styled from 'styled-components';
// import { mobile, pc } from '../../responsive';


export const Nav = styled.div`
  height: 5rem;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
`;
  export const Brand = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
    display:flex;
    color: ${(props) => props.theme.title};

    .h1{
      justify-content: center;
      align-content:center;
    }
  `;
  
  export const Toggle = styled.div`
   display: none;
  `;


export const ListContainer = styled.ul`
    /* flex: 1; */
    align-items: center;
    justify-content: center;
    flex: 2;
    display: flex;
    gap: 2rem;
    list-style-type: none;

    @media screen and (max-width: 768px) {
		display: none;
    }
`;

export const ItemWrap = styled.li`

`;

export const MenuItem = styled.a`
        text-decoration: none;
        color: ${(props) => props.theme.normal};
        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.1s ease-in-out;
        &:hover {
          color: ${(props) => props.theme.hover};
        }
`; 
      
  

  export const Left = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  
  
  `;

  

  // @media screen and (min-width: 280px) and (max-width: 960px) {
  //   .brand {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     width: 100%;
  //     .toggle {
  //       display: block;
  //     }
  //   }
  //   ul {
  //     display: none;
  //   }
  //   .social {
  //   }
  // }
