import styled from "styled-components";
import { mobile } from "../../responsive";


export const Container = styled.div`
    background-color: aliceblue;
    display: flex;
    ${mobile({ flexDirection: "column" })}
  `;
  
  export const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;
  
  export const Logo = styled.h1``;
  
  export const Desc = styled.p`
    margin: 20px 0px;
  `;
  
  export const SocialContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  `;
  
  export const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: 0.3s ease-in-out;

    &:hover{
      transform: scale(1.2);
    }

  `;
  
  export const Center = styled.div`
    flex: 3;
    padding: 40px;
    justify-content: center;
    align-items: center;
    margin: auto;
    ${mobile({ display: "none" })}
  `;
  
  export const Title = styled.h3`
    margin: 10px 0;    
  `;
  
//   const List = styled.ul`
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     display: flex;
//     flex-wrap: wrap;
//   `;
  
//   const ListItem = styled.li`
//     width: 50%;
//     margin-bottom: 10px;
//   `;
  
export const Right = styled.div`
    flex: 1;
    margin: auto;
    padding: 20px;
  
  `;
  
  export const ContactItem = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  `;
  
//   const Payment = styled.img`
//       width: 50%;
//   `;