import React from 'react'
import { ServicesTitle, Ico, Desc, Service, Container, Section } from './componetsStyles/ServicesStyles';
import { servicesData } from '../data/servicesData';


const Services = () => {
  return (
    <Section smPadding="50px 10px" position="relative" id="services">
        <ServicesTitle>Nuestros servicios</ServicesTitle>
        <Container>
            {servicesData.map((service,index) => {
                return(
                    <Service>
                        <Ico>
                        {service.icon}
                        </Ico>
                        <Desc>
                            <h3>{service.title}</h3>
                            <p>{service.subTitle}</p>
                        </Desc>
                    </Service>
                );
            })}
            {/* {servicesData.map((service,index) => {
                return (
                    <Services>
                        <Icon src={service.icon} alt=''/>
                        <h3> {service.title} </h3>
                        <p> {service.subTitle} </p>

                    </Services>
                )
            })} */}
        </Container>
        
    </Section>
  )
}

export default Services