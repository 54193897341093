import styled from 'styled-components';

export const StyledApp = styled.div`
  min-height: 100vh;
    /* padding-top: 10rem; */
  background-color: ${(props) => props.theme.body};
  transition: all 0.25s ease;
`; 

export const darkTheme = {
  body: '#1c1c1c',
  title: '#fff',
  subtitle: '#b6b6b6',
  icon: '#b6b6b6',
  normal: '#20b3c9',
  hover: '#46d9f0',
  hoverDark: '#1d9fb3',
}

export const lightTheme = {
  body: '#fff',
  title: '1c1c1c',
  subtitle: '#333',
  icon: '#1c1c1c',
  normal: '#20b3c9',
  hover: '#46d9f0',
  hoverDark: '#1d9fb3',
   
}

