import styled from "styled-components";

export const ServicesTitle = styled.h2`
    text-align: center;
    font-size: clamp(1.3rem, 13vw, 3.1rem);
    line-height: 1.06;
    margin: auto;
    color: ${(props) => props.theme.normal};

`;

export const Section = styled.section `
    margin-top: 4rem;
`;

export const Container = styled.div`
    width: 1240px;
    justify-content: center;
    position: relative;
    margin: auto;

    padding: 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media screen and (max-width: 1100px) {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 3rem;
        width: 85%;
	}

	@media screen and (max-width: 568px) {
		grid-template-columns: repeat(1, 1fr);
        width: 85%;
	}

`;

export const Service = styled.div`
    display: flex;
    gap: 1rem;
    padding: 2rem;
    margin: 1rem;
    background-color: aliceblue;
    border-radius: 1.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    } 

    @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    } 
     @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
    }
`;

export const Ico = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin: auto;
`;

export const Desc = styled.div`
    flex: 3;
`;






