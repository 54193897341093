import React from 'react';

import { BsFillShieldLockFill } from 'react-icons/bs';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { GiBrain,GiNetworkBars } from 'react-icons/gi';
const iconStyle = (Icon) => <Icon size="3rem" color="#20b3c9" />;

export const featuresData = [

	
    {
		name: 'Experiencia',
		description: 'Desarollamos el manejo de la recrutacion desde 2000',
		icon: iconStyle(GiBrain),
		imgClass: 'one',
	},
    {
		name: 'Productividad',
		description: 'Adecuada persona en su puesto te ayda en la productividad de tu empresa',
		icon: iconStyle(GiNetworkBars),
		imgClass: 'two',
	},
	{
		name: 'Support',
		description: 'Durantez el proceso pueden contar con nuestra ayuda ',
		icon: iconStyle(BiSupport),
		imgClass: 'three',
	},

    {
		name: 'Seguridad',
		description: 'Todos los datos se quedan en manos de los profesionales',
		icon: iconStyle(BsFillShieldLockFill),
		imgClass: 'four',
	},
    {
		name: 'Online',
		description:
			'El proceso realizamos online, para ganar tu tiempo',
		icon: iconStyle(AiOutlineCloudUpload),
		imgClass: 'five',
	},
	{
		name: 'Precio',
		description: 'Mejor precio de este servicio',
		icon: iconStyle(BiDollar),
		imgClass: 'six',
	},

];  