import { BiSolidUserDetail } from 'react-icons/bi';
const iconStyle = (Icon) => <Icon size="4rem" color="#0077b6" />;

export const servicesData = [

	
	{
		icon: iconStyle(BiSolidUserDetail),
		title: "Evaluacion psicologica detallada",
		subTitle:
		  "Nuestros psicologos utilizan profesionales herramientas cuales ayudan a conocer las capacidades de tu nuevo personal",
	  },
	  {
		icon: iconStyle(BiSolidUserDetail),
		title: "Coordinacion con tu equipo de recrutación ",
		subTitle:
		  "Coordinamos el proceso con tu equipo de recrutación para ayudar a tu empresa en mejor manera.",
	  },
	  {
		icon: iconStyle(BiSolidUserDetail),
		title: "Flexibles horarios",
		subTitle:
		  "Ajustamos flexible nuestro horario a cada empresa",
	  },
	  {
		icon: iconStyle(BiSolidUserDetail),
		title: "Resumen detallado",
		subTitle:
		  "Despues de todo proceso recibes el resumen firmado por el psicólogo especializado y durante una renion nuestro profesional lo explica y responde a cada pregunta",
	  }

];