import React from 'react'
// import { Section } from '../styles/global'
import { Section, Content, MainImg, Title, SubTitle, Button } from './componetsStyles/HeroStyles'

const Hero = () => {
  return (
    <Section>
        {/* <MainImg src="./assets/hero.png"/> */}
        <MainImg src="../assets/hero.png"/>
        <Content>
            <Title>EPPE</Title>
            <SubTitle>Especialistas psicologos para tu empresa</SubTitle>
            <Button>Contacto</Button>
        </Content>

    </Section>
  )
}

export default Hero
