import React, {useState} from 'react'

import { 
    Section, 
    StyledFormWrapper, 
    StyledForm, 
    StyledInput, 
    StyledTextArea,  
    StyledButton, 
    StyledError, 
    ContactTitle} from './componetsStyles/ContactStyles';


const initalState = {
    name: '',
    email: '',
    message: '', 
  };


const Contact = () => {

    const [state, setState] = useState(initalState);
    const [error, setError] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        console.log('submitted!');
        console.log(state);

        for (let key in state) {
        if (state[key] === '') {
            setError(`Tienes que ingresar tu ${key}`)
            return
        }
        }
        setError('');
        // const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        // const test = regex.test(state.email);
        // console.log(test);

        console.log("Succeeded!!!")
    };

    const handleInput = e => {
        const inputName = e.currentTarget.name;
        const value = e.currentTarget.value;

        setState(prev => ({ ...prev, [inputName]: value }));
    };

 return (
   <Section id="contact">
   <ContactTitle> Escribenos </ContactTitle>
   <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit}>
            <label htmlFor="name">Nombre </label>
            <StyledInput 
                type="text" 
                name="name" 
                value={state.name}
                onChange={handleInput}
                />
            <label htmlFor="email">Email: </label>
            <StyledInput 
                type="text" 
                name="email" 
                value={state.email}
                onChange={handleInput}
                />
            
            <label htmlFor="message"> Message</label>
            <StyledTextArea name="message" value={state.message} onChange={handleInput} />
            {error && (
                <StyledError>
                    <p>{error}</p>
                </StyledError>
            )}
            <StyledButton type="submit">Send message</StyledButton>
        </StyledForm>
    </StyledFormWrapper>
    
   </Section>
 )
}

export default Contact