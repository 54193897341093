import styled, {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *{
       
        font-family: 'Montserrat', sans-serif
    }
`;

export const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;

    padding: 0 50px;

    @media screen and (max-width: 960px) {
        padding: 0 30px;
    }
`;

export const MainHeading = styled.h1`
/* font-size: clamp(2.3rem, 6vw, 4.5rem); */
    margin-bottom: 2rem;
    width: 100%;
    letter-spacing: 4px;
    text-align: center;
`;

export const Section = styled.section`

   
/* @media screen and (max-width: 768px) {
    padding: ${({ smPadding }) => (smPadding ? smPadding : '30px 0')};
} */
`;



export default GlobalStyle;