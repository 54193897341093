import React from 'react';
import {FaFacebook, FaInstagram,FaTwitter} from "react-icons/fa";
import {FiMail} from 'react-icons/fi';
import {AiTwotonePhone} from 'react-icons/ai';
import {BsPinterest} from 'react-icons/bs';

import {
    Container, Left, Logo, Desc, SocialContainer, SocialIcon, Center, Title, Right, ContactItem
} from './componetsStyles/FooterStyles'



const Footer = () => {
    return (
      <Container>
        <Left>
          
          <SocialContainer>
            <SocialIcon color="3B5999">
              <FaFacebook />
            </SocialIcon>
            <SocialIcon color="E4405F">
              <FaInstagram />
            </SocialIcon>
            <SocialIcon color="55ACEE">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon color="E60023">
              <BsPinterest/>
            </SocialIcon>
          </SocialContainer>
        </Left>
        <Center>
        <Logo>EPPE.</Logo>
          <Desc>
            Especialistas profesionales para tu empresa
          </Desc>
        </Center>
        <Right>
          <Title>Contact</Title>
         
          <ContactItem>
            <AiTwotonePhone style={{marginRight:"10px"}}/> +1 234 56 78
          </ContactItem>
          <ContactItem>
            <FiMail style={{marginRight:"10px"}} /> contact@eppe.pe
          </ContactItem>
          
        </Right>
      </Container>
    );
  };
  
  export default Footer;